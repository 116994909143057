import React from 'react';
import { injectIntl, Link } from 'gatsby-plugin-intl';
import { Section, Column } from '../components/section';
import SEO from '../components/seo';

const NotFoundPage = ({ intl }) => {
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'notfound.title' })} />
      <Section>
        <Column>
          <p>
            {intl.formatMessage({ id: 'notfound.text' })}{' '}
            <span role="img" aria-label="duh">
              😓
            </span>
          </p>
          <Link to="/">{intl.formatMessage({ id: 'notfound.button' })}</Link>
        </Column>
      </Section>
    </>
  );
};

export default injectIntl(NotFoundPage);
