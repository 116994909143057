import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-flexbox-grid';

const Container = styled.div`
  background: ${props => props.background || 'none'};
`;

const Content = styled(Row)`
  margin: auto;
  max-width: 1280px;
  padding: 0 2em;
`;

export const Column = Col;
export const Section = ({ background, children, ...rest }) => {
  return (
    <Container background={background}>
      <Content {...rest}>{children}</Content>
    </Container>
  );
};
