import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import spanishShareImage from '../assets/bidali-cashback-spanish.jpg';
import englishShareImage from '../assets/bidali-cashback-english.jpg';

const SEO = ({ intl, title, ...props }) => {
  const date = new Date();
  const lastUpdated = date.getTime();
  
  return (
  <Helmet
    htmlAttributes={{ lang: `${intl.formatMessage({ id: 'lang' })}` }}
    title={title}
    titleTemplate="%s ― Bidali"
    meta={[
      {
        name: `description`,
        content: `${intl.formatMessage({ id: 'description' })}`,
      },
      {
        property: `og:title`,
        content: `${intl.formatMessage({ id: 'title' })}`,
      },
      {
        property: `og:description`,
        content: `${intl.formatMessage({ id: 'description' })}`,
      },
      {
        property: `og:url`,
        content: `${intl.formatMessage({ id: 'url' })}`,
      },
      {
        property: `image`,
        content: intl.locale === 'es' ? `https://cashback.bidali.com${spanishShareImage}` : `https://cashback.bidali.com${englishShareImage}`,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:image`,
        content: intl.locale === 'es' ? `https://cashback.bidali.com${spanishShareImage}` : `https://cashback.bidali.com${englishShareImage}`,
      },
      {
        property: `og:updated_time`,
        content: lastUpdated,
      },
      {
        property: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        property: `twitter:creator`,
        content: `@bidalihq`,
      },
      {
        property: `author`,
        content: `${intl.formatMessage({ id: 'author' })}`,
      },
      {
        property: `fb:app_id`,
        content: `791383627951614`,
      }
    ]} {...props} />
  );
}

export default injectIntl(SEO);
